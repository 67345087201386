.wrapper {
  display: flex;
  flex-direction: column;
  /* max-width: 524px; */
  max-width: 50%;
  margin: 0 auto;
  width: 100%;
  text-align: justify;
  letter-spacing: 0px;
  height: 100%;
}

.pageWrapper {
  border-radius: 4px;
  padding: 96px 72px;
  box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  position: relative;
  height: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fefcea+0,efeee8+100 */
  background:  #fefcea; /* Old browsers */
  background: linear-gradient(to right, #fefcea 0%, #efeee8 100%);;
  color: #343434;
  order: 1;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .wrapper {
      max-width: 768px;
  }
}

@media (max-width: 500px) {
  .pageWrapper {
      padding: 40px;
  }
}

@media (max-width: 320px) {
  .pageWrapper {
      padding: 32px;
  }
}
