:root {
    --color: #343434;
    --bg: #fefcea;
    --bg-gradient: linear-gradient(to right, #fefcea 0%, #efeee8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    --body-bg: #f8f9fa;
    --main-color: #eca681;
    --font-size: 16px;
    --outline-bg: #d3b18c6b;
}

#app {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 800px;
    padding: 24px;
    -webkit-font-smoothing: auto;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: Montserrat, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--body-bg);
    height: 100%;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: none;
    scroll-behavior: smooth;

    /* overflow: hidden; */
}

blockquote {
    text-align: center;
    font-style: italic;
    font-size: var(--font-size);
    margin-bottom: 64px;
}

h1 {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.3;
    margin: 0;
}

h2 {
    font-size: calc(var(--font-size) * 2);
    font-weight: 500;
    line-height: 1.3;
    margin: 32px 0 24px 0;
    width: 100%;
    padding-bottom: 12px;
}

h3 {
    font-size: var(--font-size);
    font-weight: 600;
    line-height: 1.7;
    margin: 24px 0 16px 0;
    padding-top: 10px;
}

h4 {
    font-size: var(--font-size);
    font-weight: 700;
    line-height: 1.3;
    margin: 16px 0 16px 0;
}

p {
    line-height: 1.7;
    margin-top: 16px;
    margin-bottom: 16px;
}

.credit-text {
    margin-bottom: 16px;
}

.italic {
    font-style: italic;
}
/* Global styles for the book */
.homeWrapper,
.wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    /* max-width: 35%; */
    margin: 0 auto;
    text-align: justify;
    letter-spacing: 0px;
    width: 860px;
    height: 100%;
    font-size: var(--font-size);
}

.video-wrapper {
    break-after: column;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
}

.video-container {
    overflow: hidden;
    position: relative;
    width: 99%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-play-button-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.video-play-button {
    width: 64px;
    height: 45px;
}

#player {
    background-color: #000;
}

.legals {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 95%;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
}

.mt-16 {
    margin-top: 16px;
}

.mb-16 {
    margin-bottom: 16px;
}

.isbn {
    max-width: 100%;
    margin: 16px 0;
}

.text-center {
    text-align: center;
}

article p.skip-indent {
    text-indent: 0;
}

.index-wrapper {
    break-after: column;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
}

.index-title {
    font-size: 48px;
    font-weight: 600;
    text-transform: uppercase;
    align-self: center;
    margin: 16px 0;
}

.index-subtitle {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    align-self: center;
}
.index-list {
    margin: auto;
    font-size: 20px;
    display: flex;
    flex-direction: column;
}

.index-list li {
    padding: 12px 0;
}
.index-link {
    text-decoration: none;
    color: var(--color);
    padding: 12px 0;
    font-size: 16px;
}

.index-list a:hover {
    color: var(--main-color);
}

.dedicatoria-wrapper {
    break-after: column;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: center;
    margin: 0;
    padding-top: 20%;
}

.dedicatoria-text {
    font-size: 14px;
    font-style: italic;
    font-weight: 700;
    margin-bottom: 16px;
}

.book-title-wrapper {
    break-after: column;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
}

.book-title {
    font-weight: 700;
    font-size: 60px;
    line-height: 1.3;
}

.book-subtitle {
    font-weight: 600;
    line-height: 1.7;
    font-size: 32px;
}

.chapter-title {
    break-after: column;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    font-style: italic;
    font-size: 40px;
    line-height: 1.3;
    margin: 0;
}

.chapter-subtitle {
    break-before: avoid;
    text-align: left;
    font-size: var(--font-size);
    font-weight: 600;
    line-height: 1.7;
    margin: 24px 0 16px 0;
    padding-top: 10px;
}

.last-p {
    break-after: column !important;
}

.no-break {
    break-inside: avoid;
    display: block;
}

.inline-block {
    display: inline-block;
    text-indent: 0;
}

.items-list {
    margin: 0;
    text-indent: 16px;
    widows: 2;
    orphans: 2;
    color: var(--color);
    line-height: 1.7;
    margin-bottom: 16px;
}

.asterisk-separator {
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
    letter-spacing: 16px;
}

/* Material ui overrides */

.MuiSwitch-colorPrimary,
.MuiSvgIcon-colorPrimary {
    color: var(--main-color) !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #404448 !important;
}

.MuiSvgIcon-fontSizeLarge {
    font-size: 48px !important;
}

.MuiLinearProgress-barColorPrimary {
    background-color: var(--main-color) !important;
}

.MuiCircularProgress-colorPrimary {
    color: var(--main-color) !important;
}

.MuiLinearProgress-colorPrimary {
    background-color: rgba(256, 229, 228, 0.63) !important;
}

.MuiLinearProgress-bar1Determinate {
    transition: transform 0.1s linear !important;
}

.MuiSlider-markLabel {
    font-size: var(--font-size) !important;
}

.MuiSlider-mark {
    /* background-color: #bfbfbf !important; */
    height: 8px !important;
    width: 2px !important;
    margin-top: -3px !important;
}

.MuiSlider-markActive {
    background-color: var(--main-color) !important;
}

.MuiDrawer-paper {
    background-color: var(--bg) !important;
    padding: 24px;
    max-width: 30%;
    box-sizing: border-box;
}

#messenger {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: -100px;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-in-out;
}

#messenger-inner {
    width: 100%;
    height: auto;
    padding: 24px;
    background: #424242;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    border-radius: 4px 4px 0 0;
}

.messenger-button {
    color: var(--main-color);
    font-weight: 700;
    cursor: pointer;
}

@media (max-width: 2048px) {
    .homeWrapper,
    .wrapper {
        max-width: 600px;
    }
}

@media (max-width: 1024px) {
    /* #app {
        min-height: 100%;
    } */
    /* .wrapper {
        width: 700px;
    } */
}

@media (max-width: 768px) {
    html {
        height: 100%;
        width: 100%;
    }
    body {
        font-size: 16px;
        line-height: 24px;
        /* padding: 0; */
    }
    .homeWrapper,
    .wrapper {
        width: 640px;
    }
}
@media (max-height: 1024px) {
    /* .wrapper {
        width: 600px;
    } */
    /* .chapter-title {
        height: 100% !important;
        font-size: 32px;
    }
    .legals {
        font-size: 10px;
    }
    .book-title {
        font-size: 40px;
    }

    .book-subtitle {
        font-size: 24px;
    }

    .index-title {
        font-size: 32px;
    }

    .index-subtitle {
        font-size: 18px;
    }
    .index-list {
        font-size: 16px;
    }

    .index-list li {
        padding: 4px 0;
    } */
}

/* @media (max-height: 600px) {
    .wrapper {
        width: 296px;
    }

    .chapter-title {
        height: 100% !important;
        font-size: 24px;
    }
    .legals {
        font-size: 6px;
    }
    .book-title {
        font-size: 32px;
    }

    .book-subtitle {
        font-size: 24px;
    }

    .index-title {
        font-size: 32px;
    }

    .index-subtitle {
        font-size: 18px;
    }
    .index-list {
        font-size: 12px;
    }

    .index-list li {
        padding: 0;
    }
}*/

@media (max-width: 500px) {
    html {
        height: 100%;
    }
    body {
        font-size: 16px;
        line-height: 24px;
        overflow: hidden;
    }
    #app {
        padding: 0;
        min-height: 100%;
    }
    .chapter-title {
        /* height: 100% !important; */
        font-size: 32px;
    }
    .wrapper {
        width: 100%;
    }
    .legals {
        font-size: 10px;
    }
    .book-title {
        font-size: 40px;
    }

    .book-subtitle {
        font-size: 24px;
    }

    .index-title {
        font-size: 32px;
    }

    .index-subtitle {
        font-size: 18px;
    }
    .index-list {
        font-size: 16px;
    }
    .index-link {
        font-size: 16px;
        padding: 8px 0;
    }

    .index-list li {
        padding: 4px 0;
    }

    .MuiDrawer-paper {
        max-width: 75%;
    }

    .isbn {
        max-width: 50%;
        height: 70%;
        margin: 8px 0;
    }
    .dedicatoria-text {
        font-size: 12px;
    }
}

@media (max-height: 600px) {
    .legals {
        font-size: 10px;
    }

    .mt-16 {
        margin-top: 8px;
    }

    .mb-16 {
        margin-bottom: 8px;
    }
    .isbn {
        max-width: 50%;
        height: 70%;
        margin: 8px 0;
    }
}
